<template>
<div>
    <div class="row justify-content-center py-16 px-8 py-md-16 px-md-0 bgi-size-cover bgi-position-center" style="background: #2a84a6 url('')">
    <div class="col-md-6 text-white">
      <div class="d-flex ">
        <div class="d-flex flex-column px-0 order-2 order-md-1">
            <h2 class="display-3 font-weight-boldest order-1" v-html="$t('PRIVACY.TITOLO')"></h2>
            <h3 class="mb-10 order-2" v-html="$t('PRIVACY.SOTTOTITOLO')"></h3>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!getPrivacyPolicy" class="row justify-content-center py-8 px-8 py-md-20 px-md-0 bg-default">
    <div class="col-lg-8 col-xl-6">
      <div class="row align-items-center mt-5">
        <h4>

        </h4>
        <h3 class="subtitle" v-html="$t('PRIVACY.INTRO')"></h3>
         <p class="lead" v-html="$t('PRIVACY.INTRODESCRIZIONE1')"></p>
         <p class="lead" v-html="$t('PRIVACY.INTRODESCRIZIONE2')"></p>

        <h3 class="subtitle" v-html="$t('PRIVACY.TIPOLOGIA')"></h3>
         <p class="lead" v-html="$t('PRIVACY.TIPOLOGIADESCRIZIONE1')"></p>
         <p class="lead" v-html="$t('PRIVACY.TIPOLOGIADESCRIZIONE2')"></p>

        <h3 class="subtitle" v-html="$t('PRIVACY.FINALITA')"></h3>
         <p class="lead" v-html="$t('PRIVACY.FINALITADESCRIZIONE1')"></p>
         <p class="lead" v-html="$t('PRIVACY.FINALITADESCRIZIONE2')"></p>
         <p class="lead" v-html="$t('PRIVACY.FINALITADESCRIZIONE3')"></p>
        <h3 class="subtitle" v-html="$t('PRIVACY.MODALITA')"></h3>
         <p class="lead" v-html="$t('PRIVACY.MODALITADESCRIZIONE1')">/p>
         <p class="lead" v-html="$t('PRIVACY.MODALITADESCRIZIONE2')"></p>
         <p class="lead" v-html="$t('PRIVACY.MODALITADESCRIZIONE3')"></p>
        <h3 class="subtitle" v-html="$t('PRIVACY.SOGGETTI')"></h3>
         <p class="lead" v-html="$t('PRIVACY.SOGGETTIDESCRIZIONE1')"></p>
        <ol v-html="$t('PRIVACY.SOGGETTIDESCRIZIONE2')" class="ml-7">

        </ol>
         <p class="lead" v-html="$t('PRIVACY.SOGGETTIDESCRIZIONE3')"></p>
        <h3 class="subtitle" v-html="$t('PRIVACY.DIRITTO')"></h3>
         <p class="lead" v-html="$t('PRIVACY.DIRITTODESCRIZIONE1')"></p>
         <p class="lead" v-html="$t('PRIVACY.DIRITTODESCRIZIONE2')">

        </p>

        <h3 class="subtitle" v-html="$t('PRIVACY.MANIFESTO')"></h3>
        <p class="lead" v-html="$t('PRIVACY.MANIFESTODESCRIZIONE1')"></p>
      </div>
    </div>
  </div>
  <div v-else class="row justify-content-center py-8 px-8 py-md-20 px-md-0 bg-default">
    <div class="col-lg-8 col-xl-6">
      <div class="row align-items-center mt-5" v-html="privacyPolicy">
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "privacy",
  data() {
    return {
      privacyPolicy: "",
    }
  },
  computed: {
    getPrivacyPolicy() {
      return this.privacyPolicy != '';
    },
    ...mapGetters([
      "getOrganization",
    ]),
  },
  mounted() {
    this.privacyPolicy = this.getOrganization?.data?.privacyPolicy ?? '';
  },
};
</script>
